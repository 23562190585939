var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_c('div',[_c('div',{staticClass:"headline"},[_vm._v("Influencer rapport")]),_c('span',{staticClass:"subtitle-1 light-grey"},[_vm._v("Filtrér rapporten efter behov. Som standard vises nuværende dag.")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":function($event){_vm.filtersTmp = JSON.parse(JSON.stringify(_vm.filters));
              _vm.filterDialog = true;}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-filter")])],1)]}}])},[_c('span',[_vm._v("Filtrér")])])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filterInfluencers,"loading":_vm.loading,"hide-default-footer":"","items-per-page":10000},scopedSlots:_vm._u([{key:"body",fn:function(ref){
              var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.influencer.uuid},[_c('td',[_c('router-link',{attrs:{"to":{
                    name: 'influencer',
                    params: { id: item.influencer.uuid }
                  }}},[_vm._v(" "+_vm._s(item.influencer.user.first_name)+" "+_vm._s(item.influencer.user.last_name)+" ")])],1),_c('td',[_vm._v(_vm._s(item.clicks))]),_c('td',[_vm._v(_vm._s(item.unique_clicks))]),_c('td',[_vm._v(_vm._s(item.sales))]),_c('td',[_vm._v(_vm._s(item.conversion))]),_c('td',[_vm._v(_vm._s(item.value))]),_c('td',[_vm._v(_vm._s(item.commission))])])}),0)]}}])},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"primary","indeterminate":""},slot:"progress"})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"550px"},model:{value:(_vm.filterDialog),callback:function ($$v) {_vm.filterDialog=$$v},expression:"filterDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline",attrs:{"primary-title":""}},[_vm._v(" Filtrér rapport ")]),_c('v-card-text',[_c('date-picker',{attrs:{"label":"Periode start","cancel-button":"","reset-button":""},model:{value:(_vm.filtersTmp.start_date),callback:function ($$v) {_vm.$set(_vm.filtersTmp, "start_date", $$v)},expression:"filtersTmp.start_date"}}),_c('date-picker',{attrs:{"label":"Periode slut","cancel-button":"","reset-button":""},model:{value:(_vm.filtersTmp.end_date),callback:function ($$v) {_vm.$set(_vm.filtersTmp, "end_date", $$v)},expression:"filtersTmp.end_date"}}),_c('v-flex',{staticClass:"mt-2"},[_c('business-autocomplete',{attrs:{"return-object":false},model:{value:(_vm.filtersTmp.business_uuid),callback:function ($$v) {_vm.$set(_vm.filtersTmp, "business_uuid", $$v)},expression:"filtersTmp.business_uuid"}})],1),_c('v-flex',{staticClass:"mt-2"},[_c('campaign-autocomplete',{attrs:{"return-object":false,"items":_vm.campaigns},model:{value:(_vm.filtersTmp.campaign_uuid),callback:function ($$v) {_vm.$set(_vm.filtersTmp, "campaign_uuid", $$v)},expression:"filtersTmp.campaign_uuid"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.filters = JSON.parse(JSON.stringify(_vm.filtersTmp));
            _vm.filterDialog = false;}}},[_vm._v(" Filtrér ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }