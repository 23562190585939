<template>
  <div>
    <v-card>
      <v-card-title>
        <div>
          <div class="headline">Influencer rapport</div>
          <span class="subtitle-1 light-grey"
            >Filtrér rapporten efter behov. Som standard vises nuværende
            dag.</span
          >
        </div>
        <v-spacer></v-spacer>

        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
              outlined
              color="primary"
              @click="
                filtersTmp = JSON.parse(JSON.stringify(filters));
                filterDialog = true;
              "
            >
              <v-icon small>fas fa-filter</v-icon>
            </v-btn>
          </template>
          <span>Filtrér</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="filterInfluencers"
          :loading="loading"
          hide-default-footer
          :items-per-page="10000"
        >
          <v-progress-linear
            slot="progress"
            color="primary"
            indeterminate
          ></v-progress-linear>
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.influencer.uuid">
                <td>
                  <router-link
                    :to="{
                      name: 'influencer',
                      params: { id: item.influencer.uuid }
                    }"
                  >
                    {{ item.influencer.user.first_name }}
                    {{ item.influencer.user.last_name }}
                  </router-link>
                </td>
                <td>{{ item.clicks }}</td>
                <td>{{ item.unique_clicks }}</td>
                <td>{{ item.sales }}</td>
                <td>{{ item.conversion }}</td>
                <td>{{ item.value }}</td>
                <td>{{ item.commission }}</td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="filterDialog" max-width="550px">
      <v-card>
        <v-card-title class="headline" primary-title>
          Filtrér rapport
        </v-card-title>
        <v-card-text>
          <date-picker
            v-model="filtersTmp.start_date"
            label="Periode start"
            cancel-button
            reset-button
          ></date-picker>
          <date-picker
            v-model="filtersTmp.end_date"
            label="Periode slut"
            cancel-button
            reset-button
          ></date-picker>
          <v-flex class="mt-2">
            <business-autocomplete
              v-model="filtersTmp.business_uuid"
              :return-object="false"
            ></business-autocomplete>
          </v-flex>
          <v-flex class="mt-2">
            <campaign-autocomplete
              v-model="filtersTmp.campaign_uuid"
              :return-object="false"
              :items="campaigns"
            ></campaign-autocomplete>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="
              filters = JSON.parse(JSON.stringify(filtersTmp));
              filterDialog = false;
            "
          >
            Filtrér
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import local_numbers from "@/helpers/filters/local_numbers.js";
import DatePicker from "@/components/common/filters/DatePicker";
import search_filters_url from "@/helpers/mixins/search_filters_url";
import BusinessAutocomplete from "../../autocompletes/BusinessAutocomplete";
import CampaignAutocomplete from "../../autocompletes/CampaignAutocomplete";
import { mapActions } from "vuex";

export default {
  components: {
    DatePicker,
    BusinessAutocomplete,
    CampaignAutocomplete
  },
  mixins: [search_filters_url],
  filters: { local_numbers },
  data: () => ({
    headers: [
      {
        text: "Influencer",
        value: "influencer.user.first_name",
        sortable: true
      },
      { text: "Klik", value: "clicks", sortable: true },
      { text: "Unikke klik", value: "unique_clicks", sortable: true },
      { text: "Salg", value: "sales", sortable: true },
      { text: "Konvertering", value: "conversion", sortable: true },
      { text: "Omsætning", value: "value", sortable: true },
      { text: "Kommission", value: "commission", sortable: true }
    ],
    filterDialog: false,
    filtersTmp: {},
    filters: {},
    stats: [],
    loading: false,
    campaigns: []
  }),
  computed: {
    filtersCount() {
      return Object.keys(this.filters).length;
    },
    filterInfluencers() {
      return this.stats.filter(item => item.influencer !== null);
    }
  },
  watch: {
    filters: {
      handler: function() {
        this.stats = [];
        this.setUrlSearchFilters(this.filters);
        this.getStats();
      },
      deep: true
    }
  },
  methods: {
    ...mapActions("core", { loadStats: "getStats" }),
    ...mapActions("core/campaigns", ["loadCampaigns"]),
    getStats() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      let filters = this.cloneDeep(this.filters);
      let params = {
        ...filters,
        group: ["influencer"]
      };
      if (params.campaign_uuid === null) {
        delete params["campaign_uuid"];
      }
      if (params.business_uuid === null) {
        delete params["business_uuid"];
      }
      this.loadStats(params).then(stats => {
        this.stats = stats;
        this.loading = false;
      });
    }
  },
  created() {
    this.filters = this.getUrlSearchFilters();
    if (Object.keys(this.filters).length === 0) {
      this.filters.start_date = this.$moment().format("YYYY-MM-DD");
      this.filters.end_date = this.$moment().format("YYYY-MM-DD");
    } else {
      this.getStats();
    }
    const params = {
      page: 1,
      limit: 25
    };
    this.loadCampaigns(params).then(campaigns => {
      this.campaigns = campaigns.response;
    });
  }
};
</script>
